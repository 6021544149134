import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    "search-placeholder": "Search",
    "show-filters": "",
    "show-buttons-text": "",
    "hide-columns-additional-buttons": ""
  }))
}