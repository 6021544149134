
import { defineComponent, ref } from 'vue'
import type { TableHeaders } from '@/definitions/shared/types'
import CampaignsEmailLinksFilter from '@/components/pages/campaigns/details/emails/links/CampaignsEmailLinksFilter.vue'
import CampaignsEmailLinksTable from '@/components/pages/campaigns/details/emails/links/CampaignsEmailLinksTable.vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import { campaignsLinksTableData } from '@/definitions/campaigns/data'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmEmptyState,
    CampaignsEmailLinksTable,
    CampaignsEmailLinksFilter,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const expand = ref(false)
    const search = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Link', value: 'link', expandPossible: true },
      { text: 'Total links', value: 'total' },
      { text: 'Unique clicks', value: 'unique' },
    ])
    const tableItems = ref(campaignsLinksTableData)

    return {
      tableItems,
      search,
      isEmptyMode,
      expand,
      tableHeaders,
    }
  },
})
